import React from "react";
import { User as FirebaseUser } from "firebase/auth";
import logo from "./logo.png";
import {
  Authenticator,
  Entity,
  FirebaseCMSApp,
  FirestoreTextSearchController,
  NavigationBuilder,
  NavigationBuilderProps
} from "@camberi/firecms";
import "typeface-rubik";
import "typeface-space-mono";
import { EmailAuthProvider } from "firebase/auth";
import { buildCategory } from './schemas/categories_schema';
import { buildCategoryVideo } from './schemas/categories_videos_schema';
import { buildBanner } from "./schemas/banners_schema";
import { buildSpecialty } from "./schemas/specialties_schema";
import { User, userSchema } from "./schemas/users_schema";
import { buildTag } from "./schemas/tags_schema";
import { buildPost } from "./schemas/all_posts.schema";
import { buildPage } from "./schemas/pages_schema";

const DEFAULT_SIGN_IN_OPTIONS = [
  EmailAuthProvider.PROVIDER_ID
];

// Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyDNse9Pg_XQHuA5Rq9Kl7mK2xj7EmS1-Ps",
  authDomain: "conectimed-9d22c.firebaseapp.com",
  databaseURL: "https://conectimed-9d22c.firebaseio.com",
  projectId: "conectimed-9d22c",
  storageBucket: "conectimed-9d22c.appspot.com",
  messagingSenderId: "373046668834",
  appId: "1:373046668834:web:2e90f93e4132ab902f247b",
  measurementId: "G-4HZE3BRY9V",
  // apiKey: 'AIzaSyBHws3ZggcZ15HEI9C-NtFtNNwMNiKnF5A',
  // authDomain: 'conectimed-production.firebaseapp.com',
  // databaseURL: 'https://conectimed-production.firebaseio.com',
  // projectId: 'conectimed-production',
  // storageBucket: 'conectimed-production.appspot.com',
  // messagingSenderId: '1016750905327',
  // appId: '1:1016750905327:web:f1d7cba40dd5780ab5481a',
  // measurementId: 'G-W5Q4GYW8D5'
};

function getPosts(): Promise<readonly string[]> {
  return new Promise((resolve, reject) => {
    resolve(["Hello"]);
  })
};

const textSearchController: FirestoreTextSearchController =
  ({ path, searchString }) => {
    return getPosts();
  };

export default function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    return {
      collections: [
        buildBanner,
        buildCategory,
        buildCategoryVideo,
        buildSpecialty,
        buildTag,
        buildPost,
        buildPage
      ],
    };
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
    dataSource
  }) => {
    let resp: Entity<User>;
    try {
      resp = await dataSource.fetchEntity({ path: "users", schema: userSchema, entityId: user.uid });
    } catch (e) {
      console.error();
    }
    const data = { type: resp && resp.values && resp.values.type ? resp.values.type : undefined, status: resp && resp.values && resp.values.status ? resp.values.status : undefined }
    if (!!data.status && data.status === 'active' && !!data.type && (resp.values.type === 'administrador' || resp.values.type === 'editor' || resp.values.type === 'videos')) {
      const userData = await Promise.resolve({
        roles: [resp.values.type],
      });
      authController.setExtra(userData);
    } else {
      throw Error("User is inactive or have not permissions!");
    }
    return true;
  };

  return (
    <FirebaseCMSApp
      name={"Conectimed CMS"}
      authentication={myAuthenticator}
      navigation={navigation}
      signInOptions={DEFAULT_SIGN_IN_OPTIONS}
      primaryColor={"#2184c5"}
      logo={logo}
      secondaryColor={"#0e518b"}
      locale={"es"}
      textSearchController={textSearchController}
      firebaseConfig={firebaseConfig}
      allowSkipLogin={false}
    />
  );
}
